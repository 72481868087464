export const scenario_origin = {
  STANDARD: 'standard',
  CUSTOM: 'custom',
};

export const PERMISSIONS = {
  ANALYSE: ['created', 'processing', 'available', 'error'],
  DELETE: ['draft', 'created'],
  EDIT: ['draft', 'created'],
};

export const isAnalysisAllowed = (origin, status) =>
  origin === scenario_origin.STANDARD || PERMISSIONS.ANALYSE.includes(status);

export const isScenarioAvailable = (origin, status) =>
  origin === scenario_origin.STANDARD || status === 'available';
