import { createContext, useState, useEffect, useCallback, useContext, type JSX } from 'react';

import { useQueries } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { getExport } from '@api';
import { DownloadNotification } from '@components/DownloadNotification';
import useGetToken from '@hooks/useGetToken';

import { useDownloadsNotification } from './downloadsNotification';

type ProviderProps = {
  children: JSX.Element;
};

type ContextType = {
  exportData: any[];
  onCloseNotification: (fileId: number) => void;
};

const ExportContext = createContext<ContextType>({
  exportData: [],
  onCloseNotification: (fileId: number) => undefined,
});

export const ExportNotificationProvider = ({ children }: ProviderProps) => {
  const [exportData, setExportData] = useState([]);
  const { getToken } = useGetToken();
  const { checkStatus } = useDownloadsNotification();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const exportIds = JSON.parse(localStorage.getItem('exportIds') as any) || [];

  const combinedQueries = useQueries({
    queries: exportIds.map((id) => ({
      queryKey: ['post', id],
      queryFn: async () => {
        const token = await getToken();
        return getExport(token, id);
      },
      refetchInterval: 5000,
    })),
  });

  const onCloseNotification = useCallback(
    (fileId: number) => {
      setExportData(exportData.filter((item: any) => item.id !== fileId));
      navigate(pathname, { replace: true });
    },
    [exportData, pathname, navigate],
  );

  useEffect(() => {
    combinedQueries.forEach((query) => {
      if (query.isSuccess && (query.data as { status: string }).status === 'available') {
        const {
          name,
          id: itemId,
          file_format,
        } = query.data as { name: string; id: number; file_format: string };
        if (exportData.some((report: any) => report.id === itemId)) {
          return;
        }
        setExportData([...exportData, { name, id: itemId, file_format }] as any);
        localStorage.setItem(
          'exportIds',
          JSON.stringify(exportIds.filter((id: number) => id !== itemId)),
        );
        checkStatus();
      }
    });
  }, [combinedQueries, exportIds, checkStatus, exportData]);

  return (
    <ExportContext.Provider value={{ exportData, onCloseNotification }}>
      {children}
      <DownloadNotification data={exportData} handleClose={onCloseNotification} />
    </ExportContext.Provider>
  );
};

export const useExportNotification = () => useContext(ExportContext);
