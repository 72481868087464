import type { JSX } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';

type Props = {
  children: JSX.Element | JSX.Element[];
};

const AuthWrapper = ({ children }: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const onRedirectCallback = (appState: any) => {
    navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname, {
      state: {
        appState,
      },
    });
  };

  if (pathname === '/logout' && new URLSearchParams(search).get('removeOrganisation')) {
    localStorage.removeItem('organization_id');
  }

  const organizationId = localStorage?.getItem('organization_id');

  const providerConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN!,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
    },
    onRedirectCallback,
    ...(organizationId ? { organization: organizationId } : null),
  };

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};

export default AuthWrapper;
