import type { JSX } from 'react';

import Box from '@mui/material/Box';
import { DialogProps } from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import { CloseIcon } from '../Icons';
import { classes, StyledDialog } from './styled';

export type ConfirmOptions = {
  maxWidth?: DialogProps['maxWidth'];
  title?: string | (JSX.Element & string) | null;
  content?: string | (JSX.Element & string) | JSX.Element | null;
  cancelTitle?: string | null;
  okTitle?: string | null;
  flexButtons?: boolean;
  handleCancel?: () => void;
  handleOk?: () => void;
  catchOnCancel?: boolean;
  customClass?: string;
  showAlert?: boolean;
  alertContent?: string;
};

const ConfirmDialog = ({
  open,
  maxWidth = 'sm',
  title,
  content,
  cancelTitle,
  okTitle,
  flexButtons,
  handleCancel,
  handleOk,
  customClass,
}: ConfirmOptions & DialogProps) => {
  const { t } = useTranslation(['common']);
  return (
    <StyledDialog
      className={clsx(classes.dialog, customClass)}
      disableRestoreFocus
      maxWidth={maxWidth}
      open={open}
      onKeyDown={(e) => {
        e.key === 'Escape' && handleCancel && handleCancel();
        e.key === 'Enter' && handleOk && handleOk();
      }}
    >
      <Box className={classes.title}>
        <Typography variant="h5">{title}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCancel}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.content}>{content}</Box>
      <Box className={clsx(classes.actions, flexButtons && 'flex')}>
        <Button fullWidth variant="contained" autoFocus onClick={handleOk} color="primary">
          {okTitle || t('Ok')}
        </Button>
        <Button fullWidth variant="outlined" color="primary" onClick={handleCancel}>
          {cancelTitle || t('Cancel')}
        </Button>
      </Box>
    </StyledDialog>
  );
};

export default ConfirmDialog;
