import { ColumnSort } from '@tanstack/react-table';
import { JsonDecoder } from 'ts.data.json';

import {
  AnalysisMode,
  ApiKey,
  ApiKeyPost,
  AppConfig,
  AppDocument,
  AssetClassBulkFund,
  AssetMatchesColumn,
  BulkFileInfo,
  BulkFileParams,
  BulkFund,
  BulkFundPreview,
  ClimateModel,
  CompanyCharacteristics,
  CorporateActivity,
  CorporateEntity,
  CorporateMODEntity,
  CorporateMODEntityResponse,
  Country,
  CreditRating,
  EnsembleMember,
  EntityHolding,
  EntityHoldingsResponse,
  Filters,
  Fund,
  FundImportPreview,
  FundInList,
  FundOption,
  FundOptionsResponse,
  FundPatch,
  FundsResponse,
  Holdings,
  IndicatorData,
  IsinEntity,
  IsinEntityResponse,
  IsinHolding,
  MapboxState,
  Metric,
  Model,
  ModelFile,
  MODHolding,
  PatPathwaysParameters,
  PendingFunds,
  PortfolioConfig,
  PortfolioConfigColumn,
  PrivateEquityHolding,
  RealAssetEntity,
  RealAssetEntityResponse,
  RealAssetHolding,
  RealEstateHolding,
  Region,
  ReportFileParams,
  SavedFilter,
  Scenario,
  ScenarioExtraParam,
  ScenarioMode,
  Sector,
  SectorRegionsPreview,
  SecurityRoute,
  SecurityRouteLayout,
  SecurityRouteMode,
  SelectOption,
  SovereignPat,
  Preferences,
  SettingsPortfolioBenchmarkFilters,
  SettingsPortfolioFilters,
  SettingsPortfolioNormalFilters,
  User,
  UserInfo,
  UserProperties,
  UsersResponse,
  WarmingLevelOption,
  ScenarioResponse,
  CustomScenarioPreview,
  Entitlement,
} from './models';

export const assetClassBulkFundDecoder = JsonDecoder.object<AssetClassBulkFund>(
  {
    listed: JsonDecoder.optional(JsonDecoder.number),
    real_estate: JsonDecoder.optional(JsonDecoder.number),
    private_equity: JsonDecoder.optional(JsonDecoder.number),
    corporate: JsonDecoder.optional(JsonDecoder.number),
    corporate_mod: JsonDecoder.optional(JsonDecoder.number),
    real_asset: JsonDecoder.optional(JsonDecoder.number),
    sovereign_pat: JsonDecoder.optional(JsonDecoder.number),
  },
  'AssetClassBulkFund',
);

export const tOrNull = <T>(decoder: JsonDecoder.Decoder<T>): JsonDecoder.Decoder<T | null> => {
  return JsonDecoder.oneOf<T | null>(
    [decoder, JsonDecoder.isNull(null)],
    `Optional:${typeof decoder}`,
  );
};

export const selectOptionDecoder = JsonDecoder.object<SelectOption>(
  {
    name: JsonDecoder.string,
    value: JsonDecoder.string,
    searchTerms: JsonDecoder.optional(JsonDecoder.array(JsonDecoder.string, 'searchTerms')),
  },
  'SelectOption',
);

export const fundOptionDecoder = JsonDecoder.object<FundOption>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    status: JsonDecoder.string,
  },
  'FundOptionDecoder',
);

export const fundOptionsDecoder = JsonDecoder.array(fundOptionDecoder, 'FundOptionsDecoder');

export const fundDecoder = JsonDecoder.object<Fund>(
  {
    id: JsonDecoder.number,
    match_share: JsonDecoder.nullable(JsonDecoder.number),
    name: JsonDecoder.string,
    fund_type: JsonDecoder.string,
    display_color: JsonDecoder.string,
    created_at: JsonDecoder.string,
    updated_at: JsonDecoder.string,
    created_by: JsonDecoder.string,
    benchmark: JsonDecoder.nullable(JsonDecoder.number),
    benchmark_name: JsonDecoder.nullable(JsonDecoder.string),
    editable: JsonDecoder.boolean,
    builtin: JsonDecoder.boolean,
    status: JsonDecoder.string,
    visibility: JsonDecoder.string,
    visibility_label: JsonDecoder.string,
    total_errors: assetClassBulkFundDecoder,
    total_warnings: assetClassBulkFundDecoder,
    total_holdings: assetClassBulkFundDecoder,
    has_latest_corporate_mod_estimates: JsonDecoder.boolean,
    has_latest_corporate_estimates: JsonDecoder.boolean,
    has_latest_sovereign_pat_estimates: JsonDecoder.boolean,
    pat_emissions_estimation_year: JsonDecoder.nullable(JsonDecoder.number),
    pat_emissions_plotting_date: JsonDecoder.nullable(JsonDecoder.string),
  },
  'Fund',
);

export const fundPatchDecoder = JsonDecoder.object<FundPatch>(
  {
    id: JsonDecoder.number,
    // match_share: JsonDecoder.number,
    name: JsonDecoder.string,
    fund_type: JsonDecoder.string,
    display_color: JsonDecoder.string,
    created_at: JsonDecoder.string,
    updated_at: JsonDecoder.string,
    created_by: JsonDecoder.string,
    benchmark: JsonDecoder.nullable(JsonDecoder.number),
    // benchmark_name: JsonDecoder.nullable(JsonDecoder.string),
    editable: JsonDecoder.boolean,
  },
  'Fund',
);

export const fundInListDecoder = JsonDecoder.object<FundInList>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    fund_type: JsonDecoder.string,
    display_color: JsonDecoder.string,
    created_at: JsonDecoder.string,
    updated_at: JsonDecoder.string,
    created_by: JsonDecoder.string,
    benchmark: tOrNull(JsonDecoder.number),
    benchmark_name: tOrNull(JsonDecoder.string),
    editable: JsonDecoder.boolean,
    builtin: JsonDecoder.boolean,
    total_errors: JsonDecoder.number,
    total_warnings: JsonDecoder.number,
    status: JsonDecoder.string,
    visibility: JsonDecoder.string,
    visibility_label: JsonDecoder.string,
    has_latest_corporate_mod_estimates: JsonDecoder.boolean,
    has_latest_corporate_estimates: JsonDecoder.boolean,
    has_latest_sovereign_pat_estimates: JsonDecoder.boolean,
    pat_emissions_estimation_year: JsonDecoder.nullable(JsonDecoder.number),
    pat_emissions_plotting_date: JsonDecoder.nullable(JsonDecoder.string),
  },
  'FundInList',
);

export const fundsResponseDecoder = JsonDecoder.object<FundsResponse>(
  {
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(fundInListDecoder, 'Funds'),
  },
  'FundsResponse',
);

export const fundsDecoder = JsonDecoder.array(fundInListDecoder, 'FundsDecoder');

export const fundOptionsResponseDecoder = JsonDecoder.object<FundOptionsResponse>(
  {
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(fundOptionDecoder, 'FundOptions'),
  },
  'FundOptionsResponse',
);

export const pendingFundsDecoder = JsonDecoder.object<PendingFunds>(
  {
    normal: JsonDecoder.array(JsonDecoder.number, 'PendingFundsNormal'),
    benchmark: JsonDecoder.array(JsonDecoder.number, 'PendingFundsBenchmark'),
  },
  'pendingFunds',
);

const isinHoldingDecoder = JsonDecoder.object<IsinHolding>(
  {
    id: JsonDecoder.optional(JsonDecoder.number),
    isin: JsonDecoder.string,
    name: JsonDecoder.string,
    exposure: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'IsinHoldingValue',
    ),
    coverage: JsonDecoder.boolean,
    asset_class: JsonDecoder.nullable(JsonDecoder.string),
    warnings: JsonDecoder.succeed,
    errors: JsonDecoder.succeed,
  },
  'IsinHolding',
);

const realEstateHoldingDecoder = JsonDecoder.object<RealEstateHolding>(
  {
    id: JsonDecoder.optional(JsonDecoder.number),
    country: JsonDecoder.string,
    floor_area_m2: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'RealEstateHoldingFloorArea',
    ),
    building_type: JsonDecoder.string,
    exposure: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'RealEstateHoldingValue',
    ),
    warnings: JsonDecoder.succeed,
    errors: JsonDecoder.succeed,
  },
  'RealEstateHolding',
);

const modHoldingDecoder = JsonDecoder.object<MODHolding>(
  {
    id: JsonDecoder.number,
    mod_id: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingId',
    ),
    name: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingName',
    ),
    sector_classificationsystem: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingSectorClassification',
    ),
    sector: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingSector',
    ),
    sector_code: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingSectorCode',
    ),
    country: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingCountry',
    ),
    revenue: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingRevenue',
    ),
    exposure: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'MODHoldingExposure',
    ),
    company_value: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingCompanyValue',
    ),
    corporation_tax_rate: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingCorporationTaxRate',
    ),
    net_income: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingNetIncome',
    ),
    property_plant_equipment_value: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingPropertyPlantEquipmentValue',
    ),
    investment_properties_value: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingInvestmentPropertiesValue',
    ),
    scope1_emissions: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingGhgScope1',
    ),
    scope2_emissions: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingGhgScope2',
    ),
    scope3_emissions: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingGhgScope3',
    ),
    total_liabilities: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingTotalLiabilities',
    ),
    asset_class: JsonDecoder.string,
    warnings: JsonDecoder.succeed,
    errors: JsonDecoder.succeed,
    credit_rating: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingCreditRating',
    ),
    credit_rating_classification: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingCreditRatingClassification',
    ),
    ebit: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingEbit',
    ),
    initial_lgd: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingInitialLgd',
    ),
    initial_pd: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingInitialPd',
    ),
    retained_earnings: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingRetainedEarnings',
    ),
    cost_pass_through: JsonDecoder.nullable(
      JsonDecoder.oneOf<string | number>(
        [JsonDecoder.string, JsonDecoder.number],
        'MODHoldingCostPassThrough',
      ),
    ),
    insurance_premium: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingInsurancePremium',
    ),
    total_assets: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingTotalAssets',
    ),
    working_capital: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODHoldingWorkingCapital',
    ),
  },
  'MODHolding',
);

const modSectorsDecoder = JsonDecoder.object(
  {
    id: JsonDecoder.string,
    sector: JsonDecoder.string,
    percent_revenue: JsonDecoder.number,
    percent_assets: JsonDecoder.nullable(JsonDecoder.number),
  },
  'MODSectors',
);

const modRegionsDecoder = JsonDecoder.object(
  {
    id: JsonDecoder.string,
    country: JsonDecoder.string,
    percent_revenue: JsonDecoder.number,
    percent_assets: JsonDecoder.nullable(JsonDecoder.number),
  },
  'MODRegions',
);

const modEmissionTargetsDecoder = JsonDecoder.object(
  {
    id: JsonDecoder.string,
    scope: JsonDecoder.string,
    year: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODEmissionTargetsYear',
    ),
    type: JsonDecoder.string,
    value: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODEmissionTargetsValue',
    ),
  },
  'MODEmissionTargets',
);

const modRevenueTargetsDecoder = JsonDecoder.object(
  {
    id: JsonDecoder.string,
    year: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODRevenueTargetsYear',
    ),
    sector_classificationsystem: JsonDecoder.string,
    sector_code: JsonDecoder.string,
    percent_revenue: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'MODRevenueTargetsValue',
    ),
  },
  'MODRevenueTargets',
);

const realAssetHoldingDecoder = JsonDecoder.object<RealAssetHolding>(
  {
    id: JsonDecoder.number,
    asset_class: JsonDecoder.string,
    name: JsonDecoder.string,
    building_type: JsonDecoder.string,
    country: JsonDecoder.string,
    latitude: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'RealAssetHoldingLatitude',
    ),
    longitude: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'RealAssetHoldingLongitude',
    ),
    address: JsonDecoder.nullable(JsonDecoder.string),
    exposure: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'RealAssetHoldingExposure',
    ),
    entity_value: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'RealAssetHoldingEntityValue',
    ),
    value: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'RealAssetHoldingValue',
    ),
    floor_area_m2: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'RealAssetHoldingArea',
    ),
    natural_gas_consumption: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'RealAssetHoldingGasUsage',
    ),
    natural_gas_consumption_supplier_emission_factor: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'RealAssetHoldingGasSupplierEmissionFactor',
    ),
    electricity_consumption: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'RealAssetHoldingElectricityUsage',
    ),
    electricity_consumption_supplier_emission_factor: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'RealAssetHoldingElectricitySupplierEmissionFactor',
    ),
    water_consumption: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'RealAssetHoldingElectricityUsage',
    ),
    energy_efficiency_rating: JsonDecoder.string,
    cost_pass_through: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'RealAssetHoldingCostPassThrough',
    ),
    excluded_hazards: JsonDecoder.string,
    warnings: JsonDecoder.succeed,
    errors: JsonDecoder.succeed,
  },
  'RealAssetHolding',
);

const privateEquityHoldingDecoder = JsonDecoder.object<PrivateEquityHolding>(
  {
    id: JsonDecoder.optional(JsonDecoder.number),
    sector: JsonDecoder.string,
    sector_code: JsonDecoder.string,
    sector_id: JsonDecoder.nullable(JsonDecoder.number),
    sector_classificationsystem: JsonDecoder.string,
    country: JsonDecoder.string,
    exposure: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'PrivateEquityHoldingValue',
    ),
    warnings: JsonDecoder.succeed,
    errors: JsonDecoder.succeed,
    asset_class: JsonDecoder.nullable(JsonDecoder.string),
    country_id: JsonDecoder.nullable(JsonDecoder.string),
  },
  'PrivateEquityHolding',
);

const corporateEntityDecoder = JsonDecoder.object<CorporateEntity>(
  {
    id: JsonDecoder.optional(JsonDecoder.number),
    pat_id: JsonDecoder.string,
    name: JsonDecoder.string,
    isin: JsonDecoder.string,
    country: JsonDecoder.string,
    pat_asset_class: JsonDecoder.string,
    exposure: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'CorporateEntityValue',
    ),
    exposure_currency: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'CorporateEntityExposureCurrency',
    ),
    exposure_year: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'CorporateEntityExposureYear',
    ),
    evic: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'CorporateEntityMarketCapValue',
    ),
    evic_currency: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'CorporateEntityMarketCapCurrency',
    ),
    evic_year: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'CorporateEntityMarketCapYear',
    ),
    activities_count: JsonDecoder.number,
    sector_classificationsystem: JsonDecoder.string,
    sector_code: JsonDecoder.string,
    warnings: JsonDecoder.succeed,
    errors: JsonDecoder.succeed,
  },
  'CorporateEntity',
);

const corporateActivityDecoder = JsonDecoder.object<CorporateActivity>(
  {
    id: JsonDecoder.optional(JsonDecoder.number),
    entity_id: JsonDecoder.string,
    scope1_emissions_source: JsonDecoder.nullable(JsonDecoder.string),
    scope1_emissions_type: JsonDecoder.nullable(JsonDecoder.string),
    scope1_emissions_year: JsonDecoder.nullable(JsonDecoder.number),
    scope1_emissions: JsonDecoder.nullable(JsonDecoder.number),
    scope1_emissions_force: JsonDecoder.nullable(JsonDecoder.boolean),
    scope2_emissions_source: JsonDecoder.nullable(JsonDecoder.string),
    scope2_emissions_type: JsonDecoder.nullable(JsonDecoder.string),
    scope2_emissions_year: JsonDecoder.nullable(JsonDecoder.number),
    scope2_emissions: JsonDecoder.nullable(JsonDecoder.number),
    scope2_emissions_force: JsonDecoder.nullable(JsonDecoder.boolean),
    scope3_emissions_source: JsonDecoder.nullable(JsonDecoder.string),
    scope3_emissions_type: JsonDecoder.nullable(JsonDecoder.string),
    scope3_emissions_year: JsonDecoder.nullable(JsonDecoder.number),
    scope3_emissions: JsonDecoder.nullable(JsonDecoder.number),
    scope3_emissions_force: JsonDecoder.nullable(JsonDecoder.boolean),
    production_sector: JsonDecoder.nullable(JsonDecoder.string),
    production_tech: JsonDecoder.nullable(JsonDecoder.string),
    production_unit: JsonDecoder.nullable(JsonDecoder.string),
    production: JsonDecoder.nullable(JsonDecoder.number),
    production_year: JsonDecoder.nullable(JsonDecoder.number),
    revenue: JsonDecoder.nullable(JsonDecoder.number),
    revenue_year: JsonDecoder.nullable(JsonDecoder.number),
    revenue_currency: JsonDecoder.nullable(JsonDecoder.string),
    sector_classificationsystem: JsonDecoder.nullable(JsonDecoder.string),
    sector_code: JsonDecoder.nullable(JsonDecoder.string),
    country: JsonDecoder.nullable(JsonDecoder.string),
  },
  'CorporateActivity',
);

const sovereignPatDecoder = JsonDecoder.object<SovereignPat>(
  {
    id: JsonDecoder.optional(JsonDecoder.number),
    pat_id: JsonDecoder.string,
    country: JsonDecoder.string,
    exposure: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'CorporateEntityValue',
    ),
    exposure_currency: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'CorporateEntityExposureCurrency',
    ),
    exposure_year: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'CorporateEntityExposureYear',
    ),
    warnings: JsonDecoder.succeed,
    errors: JsonDecoder.succeed,
  },
  'SovereignPat',
);

export const MODHoldingsDecoder = JsonDecoder.object<Holdings>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(modHoldingDecoder, 'MODEntities'),
    total_errors: JsonDecoder.optional(JsonDecoder.number),
    total_warnings: JsonDecoder.optional(JsonDecoder.number),
  },
  'MODHoldingsResponse',
);

export const isinHoldingsDecoder = JsonDecoder.object<Holdings>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(isinHoldingDecoder, 'FundIsinEntities'),
    total_errors: JsonDecoder.optional(JsonDecoder.number),
    total_warnings: JsonDecoder.optional(JsonDecoder.number),
  },
  'IsinHoldingsResponse',
);

export const realEstateHoldingsDecoder = JsonDecoder.object<Holdings>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(realEstateHoldingDecoder, 'RealEstateHoldings'),
    total_errors: JsonDecoder.number,
    total_warnings: JsonDecoder.number,
  },
  'RealEstateHoldingsResponse',
);

export const corporateModSectorsDecoder = JsonDecoder.object<Holdings>(
  {
    count: JsonDecoder.number,
    next: JsonDecoder.nullable(JsonDecoder.string),
    previous: JsonDecoder.nullable(JsonDecoder.string),
    results: JsonDecoder.array(modSectorsDecoder, 'MODSectorsHoldings'),
    total_errors: JsonDecoder.optional(JsonDecoder.number),
    total_warnings: JsonDecoder.optional(JsonDecoder.number),
  },
  'MODSectorsHoldingsResponse',
);

export const corporateModRegionsDecoder = JsonDecoder.object<Holdings>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(modRegionsDecoder, 'MODRegionsHoldings'),
    total_errors: JsonDecoder.optional(JsonDecoder.number),
    total_warnings: JsonDecoder.optional(JsonDecoder.number),
  },
  'MODRegionsHoldingsResponse',
);

export const corporateModEmissionTargetsDecoder = JsonDecoder.object<Holdings>(
  {
    count: JsonDecoder.number,
    next: JsonDecoder.nullable(JsonDecoder.string),
    previous: JsonDecoder.nullable(JsonDecoder.string),
    results: JsonDecoder.array(modEmissionTargetsDecoder, 'MODEmissionTargets'),
    total_errors: JsonDecoder.optional(JsonDecoder.number),
    total_warnings: JsonDecoder.optional(JsonDecoder.number),
  },
  'MODEmissionTargetsResponse',
);

export const corporateModRevenueTargetsDecoder = JsonDecoder.object<Holdings>(
  {
    count: JsonDecoder.number,
    next: JsonDecoder.nullable(JsonDecoder.string),
    previous: JsonDecoder.nullable(JsonDecoder.string),
    results: JsonDecoder.array(modRevenueTargetsDecoder, 'MODRevenueTargets'),
    total_errors: JsonDecoder.optional(JsonDecoder.number),
    total_warnings: JsonDecoder.optional(JsonDecoder.number),
  },
  'MODRevenueTargetsResponse',
);

export const realAssetHoldingsDecoder = JsonDecoder.object<Holdings>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(realAssetHoldingDecoder, 'RealAssetHoldings'),
    total_errors: JsonDecoder.number,
    total_warnings: JsonDecoder.number,
  },
  'RealAssetHoldingsResponse',
);

export const privateEquityHoldingsDecoder = JsonDecoder.object<Holdings>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(privateEquityHoldingDecoder, 'FundPrivateEquities'),
    total_errors: JsonDecoder.number,
    total_warnings: JsonDecoder.number,
  },
  'PrivateEquityHoldingsResponse',
);

export const corporateEntitiesDecoder = JsonDecoder.object<Holdings>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(corporateEntityDecoder, 'CorporateEntities'),
    total_errors: JsonDecoder.number,
    total_warnings: JsonDecoder.number,
  },
  'CorporateEntitiesResponse',
);

export const corporateActivitiesDecoder = JsonDecoder.object<Holdings>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(corporateActivityDecoder, 'CorporateActivities'),
    total_errors: JsonDecoder.optional(JsonDecoder.number),
    total_warnings: JsonDecoder.optional(JsonDecoder.number),
  },
  'CorporateActivitiesResponse',
);

export const sovereignPatsDecoder = JsonDecoder.object<Holdings>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(sovereignPatDecoder, 'SovereignPats'),
    total_errors: JsonDecoder.number,
    total_warnings: JsonDecoder.number,
  },
  'SovereignPatsResponse',
);

export const fundImportPreviewDecoder = JsonDecoder.object<FundImportPreview>(
  {
    data: JsonDecoder.optional(
      JsonDecoder.array(
        JsonDecoder.array(JsonDecoder.string, 'modSectorsRegionsPreviewRow'),
        'modSectorsRegionsPreviewData',
      ),
    ),
    header: JsonDecoder.optional(
      JsonDecoder.array(JsonDecoder.string, 'modSectorsRegionsPreviewHeader'),
    ),
    head: JsonDecoder.array(
      JsonDecoder.array(JsonDecoder.string, 'fundImportPreviewHeadRow'),
      'fundImportPreviewHead',
    ),
    tail: JsonDecoder.array(
      JsonDecoder.array(JsonDecoder.string, 'fundImportPreviewTailRow'),
      'fundImportPreviewTail',
    ),
    rows: JsonDecoder.number,
    columns_validity: JsonDecoder.succeed,
    session_key: JsonDecoder.optional(JsonDecoder.string),
  },
  'FundImportPreview',
);

export const modSectorsRegionsPreviewDecoder = JsonDecoder.object<SectorRegionsPreview>(
  // TODO make data, header as required fields when will be switching to the new structure
  {
    data: JsonDecoder.optional(
      JsonDecoder.array(
        JsonDecoder.array(JsonDecoder.string, 'modSectorsRegionsPreviewRow'),
        'modSectorsRegionsPreviewData',
      ),
    ),
    header: JsonDecoder.optional(
      JsonDecoder.array(JsonDecoder.string, 'modSectorsRegionsPreviewHeader'),
    ),
    head: JsonDecoder.array(
      JsonDecoder.array(JsonDecoder.string, 'modSectorsRegionsPreviewHeadRow'),
      'fundImportPreviewHead',
    ),
    tail: JsonDecoder.array(
      JsonDecoder.array(JsonDecoder.string, 'modSectorsRegionsPreviewTailRow'),
      'fundImportPreviewTail',
    ),
    rows: JsonDecoder.number,
    columns_validity: JsonDecoder.succeed,
    session_key: JsonDecoder.optional(JsonDecoder.string),
  },
  'SectorRegionsPreview',
);

export const regionDecoder = JsonDecoder.object<Region>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    category: JsonDecoder.string,
    is_display_default: JsonDecoder.optional(JsonDecoder.boolean),
  },
  'Region',
);

export const regionsResponseDecoder = JsonDecoder.array(regionDecoder, 'Regions');

export const countryDecoder = JsonDecoder.object<Country>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
  },
  'Country',
);

export const countriesResponseDecoder = JsonDecoder.array(countryDecoder, 'Countries');

export const warmingLevelOption = JsonDecoder.object<WarmingLevelOption>(
  {
    warming_level: JsonDecoder.number,
    year: JsonDecoder.number,
    map_band: JsonDecoder.number,
  },
  'WarmingLevelOption',
);

export const scenarioDecoder = JsonDecoder.object<Scenario>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    description: JsonDecoder.nullable(JsonDecoder.string),
    sort_order: JsonDecoder.nullable(JsonDecoder.number),
    category: JsonDecoder.string,
    warming_level: JsonDecoder.array(warmingLevelOption, 'ScenarioWarmingLevel'),
    warning: JsonDecoder.nullable(JsonDecoder.string),
    model_versions: JsonDecoder.array(JsonDecoder.string, 'ScenarioModelVersions'),
    scenario_type: JsonDecoder.string,
    baseline_scenario_name: JsonDecoder.nullable(JsonDecoder.string),
    created_at: JsonDecoder.nullable(JsonDecoder.string),
    origin: JsonDecoder.string,
    parent_scenario: JsonDecoder.nullable(JsonDecoder.number),
    baseline_scenario: JsonDecoder.nullable(JsonDecoder.number),
    status: JsonDecoder.nullable(JsonDecoder.string),
    display_color: JsonDecoder.string,
    created_by: JsonDecoder.nullable(JsonDecoder.string),
  },
  'Scenario',
);
export const scenariosResponseDecoder = JsonDecoder.array(scenarioDecoder, 'Scenarios');

export const scenarioExtraParamDecoder = JsonDecoder.object<ScenarioExtraParam>(
  {
    title: JsonDecoder.string,
    query_parameter: JsonDecoder.string,
    default: JsonDecoder.string,
    options: JsonDecoder.array(selectOptionDecoder, 'scenarioExtraParamOptions'),
  },
  'scenarioExtraParam',
);

export const scenarioExtraParamsDecoder = JsonDecoder.array(
  scenarioExtraParamDecoder,
  'scenarioExtraParams',
);

export const sectorDecoder = JsonDecoder.object<Sector>(
  {
    id: JsonDecoder.oneOf<string | number>([JsonDecoder.string, JsonDecoder.number], 'sectorId'),
    name: JsonDecoder.string,
    category: JsonDecoder.string,
    code: JsonDecoder.string,
    classification: JsonDecoder.string,
  },
  'Sector',
);
export const sectorsResponseDecoder = JsonDecoder.array(sectorDecoder, 'Sectors');

export const creditRatingDecoder = JsonDecoder.object<CreditRating>(
  {
    rating: JsonDecoder.string,
    classification: JsonDecoder.string,
  },
  'CreditRating',
);

export const creditRatingsResponseDecoder = JsonDecoder.array(creditRatingDecoder, 'CreditRatings');

export const analysisResponseDecoder = JsonDecoder.object<any>({}, 'Analysis');

export const modelFileDecoder = JsonDecoder.object<ModelFile>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    filesize: JsonDecoder.number,
  },
  'ModelFile',
);

export const modelDecoder = JsonDecoder.object<Model>(
  {
    version: JsonDecoder.string,
    description: JsonDecoder.string,
    created_at: JsonDecoder.string,
    available_scenarios: JsonDecoder.array(JsonDecoder.number, 'modelAvailableScenarios'),
    default_scenarios: JsonDecoder.array(JsonDecoder.number, 'modelDefaultScenarios'),
    sector_classification: JsonDecoder.string,
    files: JsonDecoder.array(modelFileDecoder, 'ModelFiles'),
  },
  'Model',
);

export const modelsResponseDecoder = JsonDecoder.array(modelDecoder, 'Models');

export const securityRouteDecoder = JsonDecoder.object<SecurityRoute>(
  {
    order: JsonDecoder.number,
    path: JsonDecoder.string,
    title: JsonDecoder.string,
    // @ts-ignore
    asset_class: JsonDecoder.string,
    companyTargetsToggle: JsonDecoder.optional(JsonDecoder.boolean),
    includeTempAlignment: JsonDecoder.optional(JsonDecoder.boolean),
    NGFSPhysicalMacroToggle: JsonDecoder.optional(JsonDecoder.boolean),
    baseInterestRateEffectsToggle: JsonDecoder.optional(JsonDecoder.boolean),
    supplyChainCostsToggle: JsonDecoder.optional(JsonDecoder.boolean),
  },
  'securityRoute',
);

export const assetMatchesColumnDecoder = JsonDecoder.object<AssetMatchesColumn>(
  {
    key: JsonDecoder.string,
    title: JsonDecoder.string,
    sortable: JsonDecoder.boolean,
    type: JsonDecoder.optional(JsonDecoder.string),
    width: JsonDecoder.number,
  },
  'assetMatchesColumn',
);

export const analysisModeDecoder: JsonDecoder.Decoder<AnalysisMode> =
  JsonDecoder.object<AnalysisMode>(
    {
      order: JsonDecoder.number,
      key: JsonDecoder.string,
      title: JsonDecoder.string,
      switches: JsonDecoder.optional(JsonDecoder.array(JsonDecoder.string, 'analysisModeSwitches')),
      exclude_filters: JsonDecoder.optional(
        JsonDecoder.array(JsonDecoder.string, 'analysisModeExcludeFilters'),
      ),
      compare_across_options: JsonDecoder.optional(
        JsonDecoder.array(selectOptionDecoder, 'compareAcrossOptions'),
      ),
      icon: JsonDecoder.optional(JsonDecoder.string),
      tooltip: JsonDecoder.optional(JsonDecoder.string),
      modes: JsonDecoder.oneOf(
        [
          JsonDecoder.lazy(() => JsonDecoder.array(analysisModeDecoder, 'analysisModeChild')),
          JsonDecoder.isUndefined([]),
        ],
        'analysisModeChildren',
      ),
      columns: JsonDecoder.optional(
        JsonDecoder.array(assetMatchesColumnDecoder, 'assetMatchesColumns'),
      ),
      type: JsonDecoder.optional(JsonDecoder.string),
      mapbox_props: JsonDecoder.optional(JsonDecoder.succeed),
      alternate_mapbox_props: JsonDecoder.optional(JsonDecoder.succeed),
      sector_system: JsonDecoder.optional(JsonDecoder.string), // TODO change to sector_classificationsystem??
      convergence_years_select: JsonDecoder.optional(JsonDecoder.boolean),
      category: JsonDecoder.optional(JsonDecoder.string),
      risk_types: JsonDecoder.optional(JsonDecoder.array(JsonDecoder.string, 'riskTypes')),
      asset_class_filter_options: JsonDecoder.optional(
        JsonDecoder.array(JsonDecoder.string, 'assetClassFilterOptions'),
      ),
      years_select: JsonDecoder.optional(JsonDecoder.boolean),
      years_select_options: JsonDecoder.optional(
        JsonDecoder.array(JsonDecoder.number, 'yearsSelectOptions'),
      ),
    },
    'analysisMode',
  );

export const portfolioConfigColumnDecoder = JsonDecoder.object<PortfolioConfigColumn>(
  {
    key: JsonDecoder.string,
    title: JsonDecoder.string,
    sortable: JsonDecoder.boolean,
    type: JsonDecoder.string,
    fundType: JsonDecoder.optional(JsonDecoder.string),
  },
  'portfolioConfigColumn',
);

export const portfolioConfigDecoder = JsonDecoder.object<PortfolioConfig>(
  {
    fundTypes: JsonDecoder.array(JsonDecoder.string, 'portfolioConfigFundTypes'),
    columns: JsonDecoder.array(portfolioConfigColumnDecoder, 'portfolioConfigColumns'),
    assetTypes: JsonDecoder.array(JsonDecoder.string, 'portfolioConfigAssetTypes'),
    enableMultiFundUpload: JsonDecoder.boolean,
  },
  'portfolioConfig',
);

export const appDocumentDecoder = JsonDecoder.object<AppDocument>(
  {
    key: JsonDecoder.string,
    title: JsonDecoder.string,
    name: JsonDecoder.string,
    location: JsonDecoder.string,
  },
  'AppDocument',
);

export const scenarioModeDecoder = JsonDecoder.object<ScenarioMode>(
  {
    mode: JsonDecoder.string,
    title: JsonDecoder.string,
    unit: JsonDecoder.string,
    extraParams: JsonDecoder.boolean,
  },
  'scenarioMode',
);

export const appConfigDecoder = JsonDecoder.object<AppConfig>(
  {
    customAssetRoutes: JsonDecoder.array(securityRouteDecoder, 'customAssetRoutes'),
    securityRoutes: JsonDecoder.array(securityRouteDecoder, 'securityRoutes'),
    defaultAnalysisMode: JsonDecoder.string,
    analysisModes: JsonDecoder.array(analysisModeDecoder, 'analysisModes'),
    portfolioConfig: portfolioConfigDecoder,
    extraRoutes: JsonDecoder.array(JsonDecoder.string, 'appConfigextraRoutes'),
    appDocuments: JsonDecoder.array(appDocumentDecoder, 'appDocuments'),
    locationExplorerModes: JsonDecoder.optional(
      JsonDecoder.array(analysisModeDecoder, 'locationExplorerModes'),
    ),
    locationExplorerBuildingTypes: JsonDecoder.optional(
      JsonDecoder.array(JsonDecoder.string, 'locationExplorerBuildingTypes'),
    ),
    scenarioModes: JsonDecoder.array(scenarioModeDecoder, 'scenarioModes'),
  },
  'appConfig',
);

export const securityRouteModeDecoder = JsonDecoder.object<SecurityRouteMode>(
  {
    id: JsonDecoder.number,
    title: JsonDecoder.string,
    widget_type: JsonDecoder.string,
    mode: JsonDecoder.string,
    category: JsonDecoder.string,
    mapbox_props: JsonDecoder.optional(JsonDecoder.succeed),
    model_version: JsonDecoder.optional(JsonDecoder.string),
    scenarios_select: JsonDecoder.optional(JsonDecoder.boolean),
    years_select: JsonDecoder.optional(JsonDecoder.boolean),
    years_select_options: JsonDecoder.optional(
      JsonDecoder.array(JsonDecoder.number, 'yearsSelectOptions'),
    ),
    base_interest_rate_effects_toggle: JsonDecoder.optional(JsonDecoder.boolean),
  },
  'securityRouteMode',
);

export const securityRouteModesDecoder = JsonDecoder.array(
  securityRouteModeDecoder,
  'securityRouteModes',
);

export const securityRouteLayoutDecoder = JsonDecoder.object<SecurityRouteLayout>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    selected: JsonDecoder.boolean,
    shared: JsonDecoder.boolean,
    mine: JsonDecoder.boolean,
    layout: JsonDecoder.array(JsonDecoder.string, 'securityRouteLayoutLayoutField'),
    model_version: JsonDecoder.optional(JsonDecoder.string),
    asset_class: JsonDecoder.optional(JsonDecoder.string),
  },
  'securityRouteLayout',
);

export const securityRouteLayoutsDecoder = JsonDecoder.array(
  securityRouteLayoutDecoder,
  'securityRouteLayouts',
);

export const patPathwaysParametersDecoder = JsonDecoder.object<PatPathwaysParameters>(
  {
    convergence_year: JsonDecoder.number,
    momentum_year: JsonDecoder.failover(2019, JsonDecoder.number),
    reference_year: JsonDecoder.failover(2019, JsonDecoder.number),
    sbti_sector: JsonDecoder.string,
    pathway_source: JsonDecoder.string,
    pathway_metric: JsonDecoder.string,
    region: JsonDecoder.string,
    momentum_scenario_version: JsonDecoder.optional(JsonDecoder.string),
    reference_scenario_version: JsonDecoder.optional(JsonDecoder.string),
    scope: JsonDecoder.object<PatPathwaysParameters['scope']>(
      {
        ghg_scope1: JsonDecoder.boolean,
        ghg_scope2: JsonDecoder.boolean,
        ghg_scope3: JsonDecoder.boolean,
      },
      'patPathwaysParametersScopes',
    ),
  },
  'patPathwaysParameters',
);

export const settingsPortfolioNormalFiltersDecoder =
  JsonDecoder.object<SettingsPortfolioNormalFilters>(
    {
      created_by: JsonDecoder.failover(
        [],
        JsonDecoder.array(JsonDecoder.string, 'filtersCreatedByDecoder'),
      ),
      benchmark: JsonDecoder.failover(
        [],
        JsonDecoder.array(JsonDecoder.string, 'filtersBenchmarkDecoder'),
      ),
      visibility: JsonDecoder.failover('', JsonDecoder.string),
      fund_status: JsonDecoder.failover(
        [],
        JsonDecoder.array(JsonDecoder.string, 'filtersFundStatusDecoder'),
      ),
      asset_classes: JsonDecoder.failover(
        [],
        JsonDecoder.array(JsonDecoder.string, 'filtersAssetClassesDecoder'),
      ),
      sorting: JsonDecoder.optional(
        JsonDecoder.array(
          JsonDecoder.object<ColumnSort>(
            {
              desc: JsonDecoder.boolean,
              id: JsonDecoder.string,
            },
            'sortingState',
          ),
          'sortingStateArray',
        ),
      ),
    },
    'SettingsPortfolioNormalFilters',
  );

export const settingsPortfolioBenchmarkFiltersDecoder =
  JsonDecoder.object<SettingsPortfolioBenchmarkFilters>(
    {
      created_by: JsonDecoder.failover(
        [],
        JsonDecoder.array(JsonDecoder.string, 'filtersCreatedByDecoder'),
      ),
      fund_status: JsonDecoder.failover(
        [],
        JsonDecoder.array(JsonDecoder.string, 'filtersFundStatusDecoder'),
      ),
      sorting: JsonDecoder.optional(
        JsonDecoder.array(
          JsonDecoder.object<ColumnSort>(
            {
              desc: JsonDecoder.boolean,
              id: JsonDecoder.string,
            },
            'sortingState',
          ),
          'sortingStateArray',
        ),
      ),
    },
    'SettingsPortfolioBenchmarkFilters',
  );

export const settingsPortfolioFiltersDecoder = JsonDecoder.object<SettingsPortfolioFilters>(
  {
    Normal: JsonDecoder.failover(
      {
        created_by: [],
        benchmark: [],
        visibility: '',
        fund_status: [],
        sorting: [],
      },
      settingsPortfolioNormalFiltersDecoder,
    ),
    Benchmark: JsonDecoder.failover(
      {
        created_by: [],
        fund_status: [],
        sorting: [],
      },
      settingsPortfolioBenchmarkFiltersDecoder,
    ),
  },
  'SettingsPortfolioFilters',
);

export const settingsDecoder = JsonDecoder.object<Preferences>(
  {
    model_version: JsonDecoder.string,
    newest_available_model_version: JsonDecoder.string,
    default_isin_for_equity_page: JsonDecoder.optional(JsonDecoder.string),
    default_isin_for_corporate_debt_page: JsonDecoder.optional(JsonDecoder.string),
    default_isin_for_sovereign_debt_page: JsonDecoder.optional(JsonDecoder.string),
    default_iso3c_for_private_equity_page: JsonDecoder.optional(JsonDecoder.string),
    default_sector_for_private_equity_page: JsonDecoder.optional(JsonDecoder.number),
    default_asset_for_real_asset_page: JsonDecoder.optional(JsonDecoder.number),
    // TODO will be required later
    is_limited_functionality_demo: JsonDecoder.optional(JsonDecoder.boolean),
    display_language: JsonDecoder.string,
    disclaimer_accepted: JsonDecoder.optional(JsonDecoder.boolean),
    scenarios: JsonDecoder.array(JsonDecoder.number, 'preferencesScenarios'),
    sector_classification: JsonDecoder.optional(JsonDecoder.string),
    pat_pathways_parameters: JsonDecoder.optional(patPathwaysParametersDecoder),
    warming_level: JsonDecoder.succeed,
    scenarios_with_baselines: JsonDecoder.optional(
      JsonDecoder.array(JsonDecoder.number, 'preferencesSelectedScenarios'),
    ),
    filters: JsonDecoder.object<Preferences['filters']>(
      {
        portfolio: JsonDecoder.failover(
          {
            Normal: {
              created_by: [],
              benchmark: [],
              visibility: '',
              fund_status: [],
              sorting: [],
            },
            Benchmark: {
              created_by: [],
              fund_status: [],
              sorting: [],
            },
          },
          settingsPortfolioFiltersDecoder,
        ),
      },
      'PreferencesFilters',
    ),
    location_explorer_metrics: JsonDecoder.succeed,
  },
  'Preferences',
);

export const userInfoDecoder = JsonDecoder.object<UserInfo>(
  {
    organisation: JsonDecoder.string,
    name: tOrNull(JsonDecoder.string),
    picture_url: tOrNull(JsonDecoder.string),
  },
  'UserInfo',
);

const entitlementDecoder = JsonDecoder.enumeration<Entitlement>(
  {
    CLIMATE_RISK: 'CLIMATE_RISK',
    PAT: 'PAT',
    LOCATION_CLIMATE_RISK: 'LOCATION_CLIMATE_RISK',
    MOD: 'MOD',
    CUSTOM_SCENARIO: 'CUSTOM_SCENARIO',
  },
  'Entitlement',
);

export const entitlementsDecoder = JsonDecoder.array(entitlementDecoder, 'Entitlements');

export const userPropertiesDecoder = JsonDecoder.object<UserProperties>(
  {
    user_info: userInfoDecoder,
    app_config: appConfigDecoder,
    preferences: settingsDecoder,
    entitlements: entitlementsDecoder,
  },
  'UserProperties',
);

export const entityHoldingDecoder = JsonDecoder.object<EntityHolding>(
  {
    entity: JsonDecoder.string,
    entity_name: JsonDecoder.string,
    entity_asset_class: JsonDecoder.string,
    exposure: JsonDecoder.oneOf<string | number | null>(
      [tOrNull(JsonDecoder.string), tOrNull(JsonDecoder.number)],
      'EntityHoldingExposure',
    ),
    value_share: JsonDecoder.number,
    is_short_position: JsonDecoder.optional(JsonDecoder.boolean),
    country_id: JsonDecoder.nullable(JsonDecoder.string),
    sector_id: JsonDecoder.nullable(JsonDecoder.string),
  },
  'EntityHolding',
);

export const entityHoldingsDecoder = JsonDecoder.object<EntityHoldingsResponse>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(entityHoldingDecoder, 'EntityHoldings'),
  },
  'EntityHoldingsResponse',
);

export const isinEntityDecoder = JsonDecoder.object<IsinEntity>(
  {
    isin: JsonDecoder.string,
    name: JsonDecoder.string,
    cusip: JsonDecoder.nullable(JsonDecoder.string),
    sedol: JsonDecoder.nullable(JsonDecoder.string),
    has_company_targets_estimates: JsonDecoder.boolean,
    leis: JsonDecoder.array(JsonDecoder.string, 'IsinEntityLei'),
  },
  'IsinEntity',
);

export const isinEntitiesResponseDecoder = JsonDecoder.object<IsinEntityResponse>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(isinEntityDecoder, 'IsinEntities'),
  },
  'FundsResponse',
);

export const corporatesModDecoder = JsonDecoder.object<CorporateMODEntity>(
  {
    name: JsonDecoder.string,
    id: JsonDecoder.string,
    user_provided_id: JsonDecoder.string,
    fund_name: JsonDecoder.string,
    has_company_targets_estimates: JsonDecoder.boolean,
  },
  'CorporateMODEntity',
);

export const corporatesModResponseDecoder = JsonDecoder.object<CorporateMODEntityResponse>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(corporatesModDecoder, 'ModEntities'),
  },
  'FundsResponse',
);

export const realAssetEntityDecoder = JsonDecoder.object<RealAssetEntity>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    address: JsonDecoder.nullable(JsonDecoder.string),
    country_id: JsonDecoder.nullable(JsonDecoder.string),
    country: JsonDecoder.nullable(JsonDecoder.string),
    latitude: JsonDecoder.nullable(JsonDecoder.number),
    longitude: JsonDecoder.nullable(JsonDecoder.number),
    fund_name: JsonDecoder.string,
  },
  'RealAssetEntity',
);

export const realAssetEntitiesResponseDecoder = JsonDecoder.object<RealAssetEntityResponse>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(realAssetEntityDecoder, 'RealAssetEntities'),
  },
  'FundsResponse',
);

export const filtersDecoder = JsonDecoder.object<Filters>(
  {
    scenarios: JsonDecoder.array(JsonDecoder.string, 'scenarioIDs'),
    funds: JsonDecoder.array(JsonDecoder.string, 'fundIDs'),
    sectors: JsonDecoder.array(JsonDecoder.string, 'sectorIDs'),
    geographies: JsonDecoder.array(JsonDecoder.string, 'geographyIDs'),
    asset_classes: JsonDecoder.array(JsonDecoder.string, 'assetClassIDs'),
  },
  'Filter',
);

export const savedFilterDecoder = JsonDecoder.object<SavedFilter>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    shared: JsonDecoder.boolean,
    filters: filtersDecoder,
    mine: JsonDecoder.boolean,
    mode: JsonDecoder.string,
    group: JsonDecoder.string,
    channel: JsonDecoder.string,
    include_benchmark: JsonDecoder.boolean,
    created_at: JsonDecoder.string,
    base_interest_rate_effects: JsonDecoder.nullable(JsonDecoder.boolean),
    ngfs_physical_macro_effects: JsonDecoder.nullable(JsonDecoder.boolean),
    company_targets: JsonDecoder.nullable(JsonDecoder.boolean),
    scaling_factor: JsonDecoder.nullable(JsonDecoder.boolean),
    supply_chain_costs_effects: JsonDecoder.nullable(JsonDecoder.boolean),
  },
  'SavedFilter',
);

export const savedFiltersDecoder = JsonDecoder.array(savedFilterDecoder, 'savedFilters');

export const bulkFileParamsDecoder = JsonDecoder.object<BulkFileParams>(
  {
    scenarios: JsonDecoder.array(JsonDecoder.string, 'bulkFileParamsScenarios'),
    funds: JsonDecoder.array(JsonDecoder.string, 'bulkFileParamsFunds'),
    asset_classes: JsonDecoder.array(JsonDecoder.string, 'bulkFileParamsAssetClasses'),
    years: JsonDecoder.array(JsonDecoder.string, 'bulkFileParamsYears'),
    metrics: JsonDecoder.array(JsonDecoder.string, 'bulkFileParamsMetrics'),
    model_versions: JsonDecoder.array(JsonDecoder.string, 'bulkFileParamsModels'),
    channels: JsonDecoder.array(JsonDecoder.string, 'bulkFileParamsChannels'),
    base_interest_rate_effects: JsonDecoder.optional(JsonDecoder.boolean),
    ngfs_physical_macro_effects: JsonDecoder.optional(JsonDecoder.boolean),
    company_targets: JsonDecoder.optional(JsonDecoder.boolean),
    supply_chain_costs_effects: JsonDecoder.optional(JsonDecoder.boolean),
  },
  'bulkFileParams',
);

export const reportFileParamsDecoder = JsonDecoder.object<ReportFileParams>(
  {
    fund: JsonDecoder.optional(JsonDecoder.number),
    entity_isin: JsonDecoder.optional(JsonDecoder.string),
    model_version: JsonDecoder.string,
    scenarios: JsonDecoder.array(JsonDecoder.string, 'reportFileParamsScenarios'),
  },
  'reportFileParams',
);

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
const fileParamsDecoder = JsonDecoder.oneOf<BulkFileParams | ReportFileParams>(
  [bulkFileParamsDecoder, reportFileParamsDecoder],
  'exportFileParams',
);

export const bulkFileInfoDecoder = JsonDecoder.object<BulkFileInfo>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    created_at: JsonDecoder.string,
    expires_at: JsonDecoder.string,
    filesize: JsonDecoder.nullable(JsonDecoder.number),
    created_by: JsonDecoder.oneOf<string | number>(
      [JsonDecoder.string, JsonDecoder.number],
      'bulkFileCreatedBy',
    ),
    shared: JsonDecoder.boolean,
    email_notification: JsonDecoder.boolean,
    // @ts-ignore
    file_format: JsonDecoder.string,
    // @ts-ignore
    status: JsonDecoder.string,
    error_text: JsonDecoder.nullable(JsonDecoder.string),
    downloaded: JsonDecoder.boolean,
    mine: JsonDecoder.boolean,
    file_params: fileParamsDecoder,
    export_type: JsonDecoder.string,
  },
  'BulkFileInfo',
);

export const bulkFileInfosDecoder = JsonDecoder.array(bulkFileInfoDecoder, 'bulkFileInfos');

export const metricDecoder = JsonDecoder.object<Metric>(
  {
    metric: JsonDecoder.string,
    definition: JsonDecoder.string,
    category: JsonDecoder.string,
  },
  'Metric',
);

export const metricsDecoder = JsonDecoder.array(metricDecoder, 'Metrics');

export const climateModelDecoder = JsonDecoder.object<ClimateModel>(
  {
    name: JsonDecoder.string,
  },
  'ClimateModel',
);
export const climateModelsDecoder = JsonDecoder.array(climateModelDecoder, 'ClimateModels');

export const ensembleMemberDecoder = JsonDecoder.object<EnsembleMember>(
  {
    name: JsonDecoder.string,
    model: climateModelDecoder,
  },
  'EnsembleMember',
);

export const indicatorDataDecoder = JsonDecoder.object<IndicatorData>(
  {
    scenario: scenarioDecoder,
    ensembleMember: ensembleMemberDecoder,
    aggregation: JsonDecoder.string,
    value: JsonDecoder.number,
    startPeriod: JsonDecoder.string.map((stringDate: string) => new Date(stringDate)),
  },
  'IndicatorData',
);

export const apiKeyPostDecoder = JsonDecoder.object<ApiKeyPost>(
  {
    id: JsonDecoder.string,
    token: JsonDecoder.string,
    is_active: JsonDecoder.boolean,
    created_at: JsonDecoder.string,
    last_used_at: JsonDecoder.nullable(JsonDecoder.string),
    expires_at: JsonDecoder.string,
  },
  'ApiKey',
);

export const apiKeyDecoder = JsonDecoder.object<ApiKey>(
  {
    id: JsonDecoder.string,
    is_active: JsonDecoder.boolean,
    created_at: JsonDecoder.string,
    last_used_at: JsonDecoder.nullable(JsonDecoder.string),
    expires_at: JsonDecoder.string,
    loading: JsonDecoder.optional(JsonDecoder.boolean),
  },
  'ApiKey',
);

export const apiKeysDecoder = JsonDecoder.array(apiKeyDecoder, 'apiKeys');

export const bulkFundDecoder = JsonDecoder.object<BulkFund>(
  {
    fund_name: JsonDecoder.string,
    fund_type: JsonDecoder.string,
    benchmark_name: JsonDecoder.string,
    asset_class: assetClassBulkFundDecoder,
    exists: JsonDecoder.boolean,
  },
  'BulkFund',
);

export const bulkfundPreviewDecoder = JsonDecoder.object<BulkFundPreview>(
  {
    head: JsonDecoder.array(bulkFundDecoder, 'bulkFundPreviewHead'),
    tail: JsonDecoder.array(bulkFundDecoder, 'bulkFundPreviewTail'),
    num_rows: JsonDecoder.number,
  },
  'BulkFundPreview',
);

export const mapboxStateDecoder = JsonDecoder.object<MapboxState>(
  {
    lat: JsonDecoder.number,
    lng: JsonDecoder.number,
    zoom: JsonDecoder.optional(JsonDecoder.number),
  },
  'MapboxState',
);

export const userDecoder = JsonDecoder.object<User>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
  },
  'User',
);

export const usersResponseDecoder = JsonDecoder.object<UsersResponse>(
  {
    count: JsonDecoder.number,
    next: tOrNull(JsonDecoder.string),
    previous: tOrNull(JsonDecoder.string),
    results: JsonDecoder.array(userDecoder, 'Users'),
  },
  'UsersResponse',
);

export const companyCharacteristicsDecoder = JsonDecoder.object<CompanyCharacteristics>(
  {
    isin: JsonDecoder.string,
    scope1_ghg_emissions_percentile: JsonDecoder.nullable(JsonDecoder.number),
    scope2_ghg_emissions_percentile: JsonDecoder.nullable(JsonDecoder.number),
    growth_rate_percentile: JsonDecoder.nullable(JsonDecoder.number),
    top_sectors_by_revenue: JsonDecoder.array(JsonDecoder.string, 'topSectorsByRevenue'),
    top_countries_by_revenue: JsonDecoder.array(JsonDecoder.string, 'topCountriesByRevenue'),
    top_countries_by_ppe: JsonDecoder.array(JsonDecoder.string, 'topCountriesByPpe'),
  },
  'CompanyCharacteristics',
);

export const companyCharacteristicsResponseDecoder = JsonDecoder.array(
  companyCharacteristicsDecoder,
  'CompanyCharacteristicsResponse',
);

export const scenarioResponseDecoder = JsonDecoder.object<ScenarioResponse>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    description: JsonDecoder.string,
    baseline_scenario: JsonDecoder.nullable(JsonDecoder.number),
    parent_scenario: JsonDecoder.nullable(JsonDecoder.number),
    display_color: JsonDecoder.string,
    status: JsonDecoder.nullable(JsonDecoder.string),
  },
  'ScenarioResponse',
);

export const customScenarioPreviewDecoder = JsonDecoder.object<CustomScenarioPreview>(
  {
    data: JsonDecoder.array(
      JsonDecoder.array(JsonDecoder.string, 'customScenarioPreviewDataRow'),
      'fundImportPreviewHead',
    ),
    header: JsonDecoder.array(JsonDecoder.string, 'customScenarioPreviewHeader'),
    rows: JsonDecoder.number,
    columns_validity: JsonDecoder.succeed,
    session_key: JsonDecoder.optional(JsonDecoder.string),
  },
  'CustomScenarioPreview',
);
