import { useState, useMemo, useCallback, type JSX } from 'react';

import { ListItemIcon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { partial } from 'lodash';
import { NavLink, useLocation } from 'react-router-dom';

import { NavDropdownArrow } from '@components/Icons';
import { NavLinks } from '@models';

import { classes } from '../styled';
import { NavTooltip } from './NavTooltip';

type Props = {
  expanded: boolean;
  autoHideTitles: string;
  links: NavLinks[];
  icon: JSX.Element;
  title: string;
  collapsedStyles?: string;
};

const NavDropdown = ({ expanded, autoHideTitles, collapsedStyles, title, icon, links }: Props) => {
  const [isDropdownOpened, setDropdownOpened] = useState(true);
  const [isTooltipOpened, setTooltipOpened] = useState(false);
  const { pathname } = useLocation();
  const isSecurityLinkActive = useMemo(
    () => links.some((link) => pathname.includes(link.to)),
    [links, pathname],
  ) as any;

  const activeDropdownStyle = isSecurityLinkActive && classes.activeDropdown;

  const handleToggleDropdown = () => {
    if (!expanded) {
      return;
    }

    setDropdownOpened(!isDropdownOpened);
  };

  const renderDropdownLinks = useCallback(() => {
    return (
      <div className={classes.subNavItems}>
        {links.map((item) => (
          <NavLink
            className={({ isActive }) =>
              clsx(classes.menuItem, classes.dropdownItem, isActive && 'active')
            }
            key={item.title}
            to={item.to}
            onClick={partial(setTooltipOpened, false)}
          >
            {item.title}
          </NavLink>
        ))}
      </div>
    );
  }, [links]);

  return (
    <Box>
      <NavTooltip
        placement="right-start"
        title={renderDropdownLinks()}
        disableHoverListener={expanded}
        open={isTooltipOpened}
        onOpen={partial(setTooltipOpened, true)}
        onClose={partial(setTooltipOpened, false)}
      >
        <div
          onClick={handleToggleDropdown}
          className={clsx(classes.dropdownTitleBox, activeDropdownStyle, collapsedStyles)}
        >
          <ListItemIcon className={clsx(classes.listItemIcon, isSecurityLinkActive && 'active')}>
            {icon}
          </ListItemIcon>
          <ListItemText className={autoHideTitles} sx={{ margin: 0 }} primary={title} />
          {expanded && (
            <NavDropdownArrow className={clsx('dropdown-arrow', isDropdownOpened && 'opened')} />
          )}
        </div>
      </NavTooltip>
      {expanded && isDropdownOpened && renderDropdownLinks()}
    </Box>
  );
};

export default NavDropdown;
