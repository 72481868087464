import { createContext, useContext, useState, useEffect, useCallback, type JSX } from 'react';

import { deleteRouteMessage, getRouteMessages } from '@api';
import { useUserProperties } from '@context/userProperties';
import useGetToken from '@hooks/useGetToken';
import { RouteMessage } from '@models';

type ContextType = {
  messages: RouteMessage[];
  error: string;
  loading: boolean;
  deleteMessageByLocation: (id: string) => void;
};

const MessagesContext = createContext<ContextType>({
  messages: [],
  error: '',
  loading: false,
  deleteMessageByLocation: () => undefined,
});

type ProviderProps = {
  children: JSX.Element;
};

export const RouteMessagesProvider = ({ children }: ProviderProps) => {
  const {
    preferences: { is_limited_functionality_demo },
  } = useUserProperties();
  const { getToken } = useGetToken();
  const [messages, setMessages] = useState<RouteMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const fetchMessages = useCallback(async () => {
    setLoading(true);
    const token = await getToken();
    getRouteMessages(token)
      .then((res) => {
        setMessages(res);
      })
      .catch((err) => {
        setError(err?.data?.error_message);
      })
      .finally(() => setLoading(false));
  }, [getToken]);

  const deleteMessageByLocation = useCallback(
    async (id: string) => {
      const token = await getToken();
      deleteRouteMessage(token, id)
        .then(() => {
          fetchMessages();
        })
        .catch((error) => setError(error?.data?.error_message));
    },
    [fetchMessages, getToken],
  );

  useEffect(() => {
    if (!is_limited_functionality_demo) {
      fetchMessages();
    }
  }, [fetchMessages, is_limited_functionality_demo]);

  return (
    <MessagesContext.Provider value={{ messages, loading, error, deleteMessageByLocation }}>
      {children}
    </MessagesContext.Provider>
  );
};

export const useRouteMessages = () => useContext(MessagesContext);
